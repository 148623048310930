<template>
  <div class="pdfContainer">
    <h2 style="text-align: center">
      {{ sessionName }}({{ this.currentIndex + 1 }}/{{ total + 1 }})
    </h2>
    <div class="pdfContainer-onlineStatus">
      <div>当前状态：</div>
      <div class="statusIcon" :class="status ? 'green' : 'red'"></div>
      <div>{{ status ? "在线" : "离线" }}</div>
      <!-- <div style="margin-left: 26px">演示者状态：</div>
      <div
        class="statusIcon"
        :class="status && presenterStatus ? 'green' : 'red'"
      ></div>
      <div>{{ status && presenterStatus ? "在线" : "离线" }}</div> -->
    </div>
    <div class="pdfContainer-content">
      <div
        id="show"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          width: 1280px;
          height: 720px;
        "
      >
        <div id="firstSlide" v-if="currentIndex === 0">
          <div>
            <div
              style="margin-bottom: 10px; text-align: center; font-size: 30px"
            >
              {{ sessionName }}
            </div>
            <vue-qr :text="watchUrl" :size="200"></vue-qr>
          </div>
        </div>
        <canvas
          v-else
          id="pdf-render"
          :key="canvasKey"
          style="width: 1280px; height: 720px"
        ></canvas>
      </div>
    </div>

    <div class="pdfContainer-footer">
      <div class="begin" v-if="!isBeginSpeech">
        <el-button type="primary" @click="beginSpeech()">开始演讲</el-button>
      </div>
      <div class="footer" v-else>
        <div class="footer-time">{{ showSpeechTime }}</div>
        <div class="footer-buttons">
          <el-button type="primary" @click="last">上一页</el-button>
          <el-button type="primary" @click="next">下一页</el-button>
          <!-- <el-button type="primary" @click="beginSpeech()">重新演讲</el-button>
          <el-button type="primary" @click="toScreen">全屏</el-button>
          <el-button type="primary" @click="toOtherScreen"
            >投屏至其他屏幕</el-button
          >
          <el-button type="primary" @click="codeVisible = true"
            >演示者二维码</el-button
          >
          <el-button type="primary" @click="isShowNote = !isShowNote">{{
            isShowNote ? "关闭笔记" : "显示笔记"
          }}</el-button> -->
          <el-button
            v-if="user_type == 'guest'"
            type="primary"
            @click="messageVisible = true"
            >咨询消息</el-button
          >
        </div>
      </div>
    </div>

    <div class="pdfContainer-note" v-if="isShowNote">
      <el-input
        @change="updatePPTNotes"
        v-model="notes[currentIndex]"
        placeholder="此页暂无演示者笔记"
        type="textarea"
        :rows="5"
      ></el-input>
    </div>

    <el-dialog
      title="演示者二维码"
      :visible.sync="codeVisible"
      :append-to-body="true"
    >
      <div style="text-align: center">
        <vue-qr :text="presentUrl" :size="260"></vue-qr>
      </div>

      <!-- {{presentUrl  }} -->
      <!-- http://localhost:8083  -->
    </el-dialog>

    <el-dialog
      title="咨询消息"
      :visible.sync="messageVisible"
      :append-to-body="true"
    >
      <div style="width: 100%; height: 90%; overflow-y: auto">
        <div v-for="(value, key, index) in messageList" :key="key">
          咨询{{ index + 1 }}

          <el-button
            style="margin-left: 5%; width: 15%"
            @click="showMessageDetail(value)"
            >查看</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="咨询消息"
      :visible.sync="messageDetailVisible"
      :append-to-body="true"
    >
      <div style="width: 100%; height: 90%; overflow-y: auto">
        <div v-for="item in messageDetail">
          <div
            class="messageDiv"
            :style="
              item.senderID == user_id
                ? 'text-align: right;'
                : 'text-align: left;'
            "
          >
            {{ item.data }}
          </div>
        </div>
      </div>

      <div style="width: 100%; height: 10%; margin-top: 30px">
        <el-input
          v-model="messageStr"
          style="width: 70%; margin-left: 5%"
        ></el-input>
        <el-button style="margin-left: 5%; width: 15%" @click="sendMessage"
          >发送</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import {
  getPPTNotes,
  getSessionInfo,
  getSessionPPT,
  updatePPTNotes,
  setShowFile,
  getShowFile,
  removeShowFile,
} from "../../api/api";
import { getUserId, getUserType } from "../../utils/store";

const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js";

export default {
  components: {
    VueQr,
  },
  data() {
    return {
      isShowNote: false,
      currentIndex: null,
      user_id: getUserId(),
      id: this.$route.query.id,
      session_id: this.$route.query.session_id,
      sessionName: "",
      color: "#000000",
      url: "",
      client: null,
      watchUrl: "",
      loading: null,
      presentUrl: "",
      codeVisible: false,
      messageVisible: false,

      pdfDoc: null,
      total: 0,
      canvasKey: 0,
      isFull: false,
      status: false,
      presenterStatus: false,
      presentationConnection: null,
      notes: [],
      speechTime: 0,
      isBeginSpeech: false,
      countDownInternal: undefined,
      showSpeechTime: "00:00",
      user_type: getUserType(),
      messageList: {},
      messageStr: "",
      messageDetailVisible: false,
      messageDetail: [],
    };
  },
  mounted() {
    console.log(11114444, this.user_type);

    this.presentUrl = `http://canhaohui-fwh.reachable-edu.com/control-pdf?id=${this.id}&session_id=${this.session_id}`;
    this.watchUrl = `http://canhaohui-fwh.reachable-edu.com/watch-pdf?id=${this.id}&session_id=${this.session_id}`;
    this.fetchData();

    // 监听全屏事件
    window.onresize = () => {
      let isFull = !!(
        document.fullscreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen ||
        document.msFullScreen
      );
      this.isFull = isFull;
      this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
    };

    document.onkeydown = (e) => {
      if (e.code === "ArrowLeft") {
        this.last();
      } else if (e.code === "ArrowRight") {
        this.next();
      }
    };
  },
  beforeDestroy() {
    removeShowFile({ session_id: this.session_id });

    if (this.client) {
      this.client.close();
    }
    //关闭投屏页面
    if (this.presentationConnection) {
      this.presentationConnection.terminate();
    }
    this.loading.close();
  },
  methods: {
    updatePPTNotes() {
      updatePPTNotes({
        id: this.id,
        notes: this.notes,
      });
    },
    getPPTNotes() {
      getPPTNotes({ ppt_url: this.url }).then((res) => {
        if (res.data.code === 0) {
          this.notes = res.data.data ? ["", ...res.data.data] : [];
        }
      });
    },
    initSocket() {
      console.log("dengs", this.user_id);

      this.client = new WebSocket("wss://team-chat.learningfirst.cn:9009");
      this.client.onopen = () => {
        console.log("WebSocket连接成功");
        this.status = true;
        this.client.send(
          JSON.stringify({
            type: "login",
            pptId: this.id,
            userId: this.user_id,
          })
        );
      };
      this.client.onmessage = (e) => {
        console.log(666, e.data);
        let obj = JSON.parse(e.data);
        if (obj.msg === "主持人状态") {
          this.presenterStatus = obj.data;
        } else if (obj.msg === "客服消息") {
          console.log(111113322, obj);

          let senderID = obj.senderID;
          let messageStr = obj.data;

          let senderMessageList = [];
          if (this.messageList[senderID]) {
            senderMessageList = this.messageList[senderID];
          }
          let messageData = [];
          messageData["data"] = messageStr;
          messageData["senderID"] = senderID;

          senderMessageList.push(messageData);

          this.messageList[senderID] = senderMessageList;

          console.log(555555, this.messageList);
        } else if (obj.msg === "登录成功" || obj.msg === "切换页面") {
          console.log(111111, obj);
          this.currentIndex = obj.data;
          this.getPdfPage(this.currentIndex < 1 ? 1 : this.currentIndex);
        }
      };
      this.client.onclose = () => {
        console.log("WebSocket连接关闭");
        this.status = false;
        this.client = null;
      };
    },

    sendMessage() {
      if (this.messageStr == "") {
        this.$message.error("请输入内容");
        return;
      }

      let info = this.messageDetail[0];

      let senderID = info.senderID;

      console.log(8888999, info);

      let senderMessageList = [];
      if (this.messageList[senderID]) {
        senderMessageList = this.messageList[senderID];
      }
      let messageData = [];
      messageData["data"] = this.messageStr;
      messageData["senderID"] = getUserId();

      senderMessageList.push(messageData);

      this.messageList[senderID] = senderMessageList;

      console.log(8888, this.messageDetail, this.messageList);

      this.client.send(
        JSON.stringify({
          type: "serviceMessage",
          pptId: this.id,
          message: this.messageStr,
          senderID: getUserId(),
          receiveID: info.receiveID,
        })
      );

      this.messageStr = "";
    },
    showMessageDetail(item) {
      this.messageDetailVisible = true;
      this.messageStr = "";
      this.messageDetail = item;
    },
    beginSpeech() {
      this.isBeginSpeech = true;
      clearInterval(this.countDownInternal);
      var timeCha = this.speechTime * 60;

      let currentTime = new Date().getTime() / 1000;
      this.client &&
        this.client.send(
          JSON.stringify({
            type: "startPPT",
            pptId: this.id,
            time: currentTime + this.speechTime * 60,
          })
        );

      console.log(123132, timeCha);
      this.countDownInternal = setInterval(() => {
        timeCha--;
        if (timeCha < 0) {
          this.showSpeechTime = "00:00";

          clearInterval(this.countDownInternal);
          resolve(true);
        } else {
          this.formatShowTime(timeCha);
        }
      }, 1000);

      setShowFile({
        ppt_id: this.id,
        session_id: this.session_id,
      }).then((res) => {
        getShowFile();
      });
    },
    formatShowTime(time) {
      // console.log('倒计时' + time)
      var theTime = time;
      var theTime1 = 0; // 分
      var theTime2 = 0; // 小时
      if (theTime > 60) {
        //超过1分钟
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60); //剩余秒数
        if (theTime1 > 60) {
          //超过60分钟
          theTime2 = parseInt(theTime1 / 60); //多少小时
          theTime1 = parseInt(theTime1 % 60);
        }
      }
      this.showSpeechTime =
        this.formatTime1(theTime1) + " : " + this.formatTime1(theTime);
    },
    formatTime1(time) {
      if (time >= 10) {
        return time;
      }
      return "0" + time;
    },
    fetchData() {
      this.loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getSessionPPT({
        id: this.id,
      }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.type === "PPT") {
            this.url = res.data.data.pdf_url;
          } else {
            this.url = res.data.data.ppt_url;
          }

          this.sessionName = res.data.data.session.name;
          if (res.data.data.notes) {
            this.notes = res.data.data.notes;
          } else {
            this.getPPTNotes();
          }

          PDFJS.getDocument(this.url).promise.then((doc) => {
            this.pdfDoc = doc;
            this.total = doc.numPages;
            this.initSocket();
            this.loading.close();
          });
        }
      });

      getSessionInfo({
        session_id: this.session_id,
      }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.speechTime) {
            this.speechTime = res.data.data.speechTime;

            if (this.speechTime > 0) {
              this.isBeginSpeech = false;
            } else {
              this.isBeginSpeech = true;
            }
          } else {
            this.isBeginSpeech = true;
          }

          console.log(1111, this.isBeginSpeech);
        }
      });
    },
    getPdfPage(number) {
      this.canvasKey++;
      this.pdfDoc.getPage(number).then((page) => {
        const canvas = document.getElementById("pdf-render");
        const context = canvas.getContext("2d");

        let show = document.getElementById("show");

        const viewport = page.getViewport({ scale: 2 });

        let viewWidth = viewport.viewBox[2];
        let viewHeight = viewport.viewBox[3];

        canvas.width = viewWidth * 2;
        canvas.height = viewHeight * 2;
        canvas.style.width = show.offsetWidth + "px";
        canvas.style.height = show.offsetHeight + "px";

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        // 进行渲染
        page.render(renderContext);
      });
    },
    next() {
      if (this.currentIndex < this.total) {
        this.isShowNote = false;
        this.client.send(
          JSON.stringify({
            type: "changeIndex",
            pptId: this.id,
            index: this.currentIndex + 1,
          })
        );
      }
    },
    last() {
      if (this.currentIndex > 0) {
        this.isShowNote = false;
        this.client.send(
          JSON.stringify({
            type: "changeIndex",
            pptId: this.id,
            index: this.currentIndex - 1,
          })
        );
      }
    },
    toScreen() {
      let myCanvas = document.getElementById("show");
      myCanvas.requestFullscreen();
    },
    toOtherScreen() {
      if (!window.PresentationRequest) {
        this.$message.warning("当前浏览器不支持投屏功能");
        return;
      }
      // 创建一个PresentationRequest对象
      let presentationRequest = new PresentationRequest(
        "/#/show-pdf?id=" + this.id + "&session_id=" + this.session_id
      );
      // 添加点击事件来开始会话
      presentationRequest.start().then((connection) => {
        // 保存连接
        this.presentationConnection = connection;
        // 添加message事件的侦听器到连接
        this.presentationConnection.addEventListener(
          "message",
          function (event) {
            console.log("Received message: " + event.data);
          }
        );
        // 设置连接的状态改变时的回调
        this.presentationConnection.onstatechange = function () {
          console.log(
            "Connection state changed to: " + presentationConnection.state
          );
        };
      });
    },
    beforeDestroy() {
      if (this.countDownInternal) {
        clearInterval(this.countDownInternal);
        this.countDownInternal = null;
      }
    },
  },
};
</script>

<style lang="scss">
.pdfContainer {
  // .pdf {
  //   width: 1280px;
  //   height: 720px;
  // }
  .pdfContainer-onlineStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    .statusIcon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 6px;
    }
    .red {
      background: red;
    }
    .green {
      background: green;
    }
  }
  .pdfContainer-content {
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    #show {
      width: 100%;
      position: relative;
      #firstSlide {
        width: 100%;
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
      }
    }
  }
  .messageDiv {
    width: 80%;
    margin-left: 10px;
    margin-top: 10px;
    font-size: 13px;
    color: #333;
  }
  .pdfContainer-footer {
    margin-bottom: 30px;
    // position: fixed;
    // width: 100vw;
    // bottom: 10px;
    // z-index: 100;
    .begin {
      width: 100%;
      text-align: center;
    }
    .footer {
      .footer-time {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
      }
      .footer-buttons {
        margin-top: 5px;
        text-align: center;
      }
    }
  }
  .pdfContainer-note {
    position: fixed;
    z-index: 100;
    width: 600px;
    margin: 0 auto;
    bottom: 55px;
    left: calc(50% - 300px);
  }
}
</style>
